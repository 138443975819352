.announcements__container {
    .page__heading {
        text-align: center;
        margin-block: var(--space-lg);
    }
    .announcement {
        color: var(--color-text-inverse);
        background-color: hsl(from var(--color-fill-theme) h s 90% / 50%);
        padding: var(--spacer-xl);
        margin: var(--spacer-xl) 0;
        border-radius: 0.5rem;
        display: grid;
        gap: var(--spacer-lg);
        position: relative;
        text-align: left;

        &__heading {
            text-align: center;
            font-size: 1.75rem;
        }

        &__details {
            display: inline-flex;
            color: var(--color-text-neutral);
            background-color: var(--color-fill-neutral);
            justify-content: center;
            gap: var(--spacer-lg);
            padding: 0.5rem 1rem;
            border-radius: 5rem;
            justify-self: center;
        }

        &__link {
            font-weight: 600;
            text-decoration: underline;
        }

        &__new {
            position: absolute;
            transform: rotate(-45deg);
            color: var(--color-fill-alert);
            font-size: 2rem;
            font-weight: 700;
            left: -1rem;
        }
    }
}
