.admin-users {
    &__cell {
        flex: 0 0 25%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media only screen and (max-width: 850px) {
        &__row {
            display: block;
            &-headings {
                display: none;
            }
        }

        &__cell {
            text-align: center;
            &-date {
                color: var(--color-text-neutral);
                background-color: var(--color-fill-neutral);
            }
        }

        .button {
            width: 100px;
        }
    }
}
