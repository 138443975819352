.alert {
    width: 120%;
    left: -10%;
    align-items: center;
    justify-content: center;
    top: var(--nav-height);
    position: absolute;
    color: var(--color-text-inverse);
    background-color: var(--color-fill-theme-highlight);
    text-align: center;
    display: none;
    gap: var(--spacer-md);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;

    &.active {
        display: flex;
    }

    &.logged-out {
        top: 0;
    }

    &.urgent {
        background-color: var(--color-fill-alert);
    }
    @media only screen and (max-width: 650px) {
        width: 100%;
        left: 0;
    }
}
