.card-profile {
    &__container {
        display: flex;
        align-items: top;
        flex-wrap: wrap;
        @media only screen and (max-width: 900px) {
            flex-direction: column;
            justify-content: center;
            flex-wrap: nowrap;
        }
    }
    &__card {
        text-align: center;
        @media only screen and (max-width: 400px) {
            margin: 5px 0;
        }
    }
    &__image {
        max-width: 220px;
    }
    &__title {
        flex: 1 0 100%;
        @media only screen and (max-width: 900px) {
            text-align: center;
        }
    }
    &__details {
        flex: 1 1 auto;
        padding-left: 10px;
        @media only screen and (max-width: 900px) {
            padding-left: 0;
        }
        & .trade-proposal-button--small {
            width: auto;
            margin: 0;
        }
    }
    &__action-bar {
        text-align: right;
        @media only screen and (max-width: 900px) {
            text-align: center;
            margin: 10px 0 0 0;
        }
        @media only screen and (max-width: 550px) {
            margin: 0;
        }

        .button {
            font-size: 0.8rem;
            display: inline-block;
            @media only screen and (max-width: 550px) {
                min-width: 100%;
            }
            min-width: 170px;
            margin: 10px 0;

            .fa {
                margin-right: 0.2rem;
                font-size: 1rem;
            }
            &:nth-child(2) {
                @media only screen and (max-width: 550px) {
                    margin-left: 0;
                }
                margin-left: 10px;
            }
        }
    }
    &__cell {
        &--name {
            flex: 1 1 100%;
            min-width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
        }
        &--amount {
            flex: 0 0 75px;
            display: flex;
            text-align: left;
            align-items: center;

            .button {
                margin: 0;
                margin-left: auto;
                width: 38px;
                justify-self: flex-end;
            }
        }
        &--trade {
            flex: 0 0 50px;
        }
    }
    &__row--headings {
        .card-profile__cell--amount {
            flex-basis: 100px;
        }
    }
    &__table--ownership {
        margin-bottom: 15px;
    }
}
