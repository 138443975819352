.draft-board {
    @media only screen and (max-width: 850px) {
        .row--headings {
            display: none;
        }

        .row {
            flex-direction: column;

            .cell {
                width: 100%;
            }
        }
    }

    .pick-column {
        flex: 0 1 5%;

        @media only screen and (max-width: 850px) {
            background-color: var(--color-fill-neutral);
            color: var(--color-text-neutral);
            border-bottom: 3px solid var(--color-fill-theme-highlight);
        }
    }

    .name-column {
        flex: 0 0 25%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        @media only screen and (max-width: 850px) {
            align-items: center;
            flex-direction: column;
            gap: 0.5rem;

            button {
                order: 1;
            }

            span {
                order: 2;
            }
        }

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        button {
            width: auto;
            margin: 0;
            padding: 0.25rem;
            flex: 0 0 auto;
            @media only screen and (max-width: 850px) {
                width: 100%;
            }
        }
    }

    .prize-column {
        flex: 0 0 45%;

        .make-pick-button {
            margin: 0;
            padding: 0.25rem;
            width: 50%;
        }
    }

    .time-column {
        flex: 0 0 25%;
    }
}

.prize-name-img-display {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .img-wrapper {
        width: 10rem;
    }

    .name-image-toggle {
        position: absolute;
        margin: 0;
        width: auto;
        right: 0;
        padding: 0.5rem;
    }
}
