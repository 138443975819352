@import "./paginator.scss";

.matches__container,
.admin-matches {
    &__cell {
        flex: 1 1 20%;
        &-date,
        &-time {
            flex: 0 0 150px;
        }
        &-id {
            flex: 0 0 50px;
        }
        &-delete,
        &-edit {
            flex: 0 0 80px;
        }
    }

    .matches__cell {
        &--date,
        &--time {
            flex: 0 0 150px;
        }
        &--player {
            flex: 1 1 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
        }
        &--id {
            flex: 0 0 50px;
        }
        &--admin-actions {
            display: flex;
            flex: 0 0 150px;
            justify-content: center;
            .button {
                width: auto;
            }
        }
    }

    .matches__title {
        display: flex;
        align-items: center;
        gap: 1rem;

        .toggle-switch {
            flex: 0 0 auto;
        }

        h2 {
            @media only screen and (max-width: 400px) {
                font-size: 3rem;
            }
        }
    }

    @media only screen and (max-width: 850px) {
        .row {
            display: block;
        }

        .matches__cell {
            text-align: center;
            justify-items: center;
            &--admin-actions {
                .button {
                    margin: 1rem;
                }
            }
            &--date {
                color: var(--color-text-neutral);
                background-color: var(--color-fill-neutral);
            }
            &-label {
                font-weight: bold;
            }
        }
    }
}
