.form {
    display: flex;
    flex-direction: column;
    background: var(--color-fill-inverse);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem 1.25rem;
    margin: 1rem 0;
    width: 100%;

    &__field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 3px solid var(--color-fill-neutral);
        transition: border 0.3s;

        label {
            font-weight: 600;
            font-size: 1.2rem;
            transition: font-size 0.3s, color 0.3s, font-weight 0.3s;

            &.with-sub-msg {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;

                .sub-msg {
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: hsl(from var(--color-text-inverse) h s 50);

                    &.interactable {
                        border: none;
                        background: none;
                        text-decoration: underline;
                        cursor: pointer;
                        padding: 0;
                    }
                }
            }
        }

        input[type="text"]:not(.browser-default),
        input[type="email"]:not(.browser-default),
        input[type="number"]:not(.browser-default),
        textarea {
            background-color: var(--color-fill-inverse);
            margin: 0;
            border: 0;
            flex: 1 1 100%;
            margin-left: 0;
            box-shadow: none;
            text-align: right;
            font-size: 1rem;
            color: hsl(from var(--color-text-inverse) h s 50%);
            transition: font-size 0.3s, color 0.3s, font-weight 0.3s;

            &:focus {
                font-size: 1.2rem;
                color: var(--color-text-inverse);
                font-weight: 600;
                box-shadow: none;
                border-bottom: 0;
                outline: 0 solid transparent;
            }
        }

        &:focus-within {
            label {
                font-size: 1rem;
                font-weight: 400;
            }
        }

        &:focus-within,
        &:hover {
            border-color: var(--color-fill-theme-highlight);
        }
    }

    textarea {
        height: 9rem;
        &:focus {
            font-size: 1rem;
        }
    }

    &__switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        label {
            font-size: 1.2rem;
            font-weight: 600;
        }

        div.switch label {
            font-size: 1rem;
            color: #989898;
        }
    }
}
