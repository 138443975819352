.cycling-background {
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    min-height: 500px;
    overflow: hidden;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: fixed;
    z-index: -1;
    -webkit-filter: saturate(2.5);
    filter: saturate(2.5);
    animation: fadeInBGFilter;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}
