.modal {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    border-radius: 2px;
    top: 0;
    left: 0;
    z-index: 99;
    padding-bottom: 2 rem;
    transition-duration: 0.51s;
    color: var(--color-text-inverse);
    text-align: center;
    justify-content: center;
    align-items: center;

    &__area {
        position: relative;
        background-color: var(--color-site-background);
        vertical-align: middle;
        overflow: auto;
        padding: 30px;
        border-radius: 2px;
        display: table;
        z-index: 1;
        max-width: 90vw;
    }

    &__content {
        max-height: 80vh;
        overflow-x: hidden;
    }

    &__title {
        margin-top: 0;
    }

    &__text-important {
        font-weight: 700;
        font-size: 1.2rem;
    }

    &__close-button {
        position: absolute;
        right: var(--spacer-xs);
        top: var(--spacer-xs);
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &.active {
        display: flex;
    }

    h3 {
        @media only screen and (max-width: 400px) {
            font-size: 1.5rem;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-evenly;
    }

    &__action-button {
        flex: 1 1 50%;
        margin: 1rem 0.5rem;
        font-weight: 700;
        max-width: 250px;
    }

    &__warning {
        font-size: 0.75rem;
        color: var(--color-text-negative);
        margin: 0;
        width: 0;
        min-width: 100%;
    }

    .warning {
        color: var(--color-text-negative);
    }

    &__notice {
        font-size: 0.75rem;
        color: var(--color-text-theme);
        margin: 0;
        width: 0;
        min-width: 100%;
    }
}
