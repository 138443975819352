.toggle-switch {
    position: relative;
    width: 110px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #bbb;
        border-radius: 20px;
        margin: 0;
        &:before {
            display: none !important;
        }
        &:after {
            display: none !important;
        }
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 34px;
            padding: 0;
            line-height: 34px;
            font-size: 14px;
            color: var(--color-text-neutral);
            font-weight: bold;
            box-sizing: border-box;
            color: var(--color-text-neutral);
        }
        &:before {
            content: attr(data-a);
            text-transform: uppercase;
            padding-left: 10px;
            background-color: var(--color-fill-positive);
        }
    }
    &-disabled {
        background-color: #ddd;
        cursor: not-allowed;
        &:before {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }
    &-inner:after {
        content: attr(data-b);
        text-transform: uppercase;
        padding-right: 10px;
        background-color: var(--color-fill-negative);
        text-align: right;
    }
    &-switch {
        display: block;
        width: 24px;
        margin: 5px;
        background-color: var(--color-text-neutral);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 75px;
        border: 0 solid #bbb;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0;
        }
        .toggle-switch-switch {
            right: 0px;
        }
    }
}
