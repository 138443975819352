.login {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__title {
        margin-top: 0;
    }

    &__content {
        width: 40%;
        min-width: 295px;
        max-width: 400px;
        background-color: var(--color-site-background);
        padding: var(--spacer-xl);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .new_user {
        width: 80%;
    }

    &__form-field {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem;

        &--text {
            flex-direction: column;
        }
        input[type="checkbox"] + label:before {
            margin-right: 0.25rem;
        }
        label {
            display: flex;
            align-items: center;
        }
    }

    &__submit-button,
    &__button {
        margin: 0.5rem auto;
    }
    &__submit-button {
        margin-top: 1rem;
        width: 100%;
    }
    &__button {
        svg {
            margin-right: 0.25rem;
        }
        width: 80%;
    }
}
