.footer {
    box-sizing: border-box;
    box-shadow: 0 -2px 2px 0 rgba(88, 24, 69, 0.14), 0 3px 1px -2px rgba(88, 24, 69, 0.12),
        0 1px 5px 0 rgba(88, 24, 69, 0.2);

    width: 100%;
    height: var(--footer-height);
    background-color: var(--color-fill-neutral);
    --footer-corner-color: hsl(from var(--color-fill-neutral) h s calc(l + 10) / 0.2);
    --footer-central-color: hsl(from var(--color-fill-neutral) h s l / 0.4);
    @supports (font: -apple-system-body) and (-webkit-appearance: none) {
        --footer-corner-color: hsl(from var(--color-fill-neutral) h s calc(l + 10%) / 0.5);
    }
    background: linear-gradient(
        56deg,
        var(--color-fill-neutral),
        var(--footer-central-color) 40%,
        var(--footer-central-color) 92%,
        var(--footer-corner-color)
    );
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    color: var(--color-text-neutral);

    &__content {
        height: 100%;
        width: 80%;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: var(--spacer-sm);

        p {
            margin: 0;
            @media only screen and (max-width: 850px) {
                font-size: 8px;
            }
        }
        .footer__user {
            align-items: center;
            display: flex;
            gap: var(--spacer-sm);
        }
    }
}
