.user-list {
    min-width: 210px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    background-color: hsl(from var(--color-fill-inverse) h s l / 0.9);

    &_user__btn {
        margin: 5px 0;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
}
