.draffle-edit {
    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;

        &--actions {
            display: flex;
            justify-content: space-around;
            gap: 1rem;
            width: 100%;

            button {
                margin: 0;
                flex: 1 1 auto;
            }
        }

        &--alert {
            font-size: 0.8rem;
            color: var(--color-text-inverse);
        }
    }
}

.edit-participants {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 1rem;
    padding: 1.5rem;
    overflow: hidden;

    &__dashboard {
        display: flex;
        flex: 1 1 auto;
        margin: 1rem 0;

        @media only screen and (max-width: 500px) {
            width: 200%;
            transition: margin-left 0.3s ease-in 0s;
        }
        &.view-in {
            @media only screen and (max-width: 500px) {
                margin-left: -100%;
            }
        }
    }

    &__picker {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0 1.5rem;

        &-choice {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            border-radius: 0.5rem;
            padding: 0.5rem;
            cursor: pointer;
            min-height: 3rem;
            width: 100%;

            &.orderable {
                border-radius: 0.5rem 0 0 0.5rem;
                flex: 1 1 auto;
            }
        }

        &-order {
            display: flex;
            flex-direction: column;

            &--button {
                background-color: var(--color-fill-neutral);
                color: var(--color-text-neutral);
                border: none;
                border-bottom: 1px solid var(--color-text-neutral);
                cursor: pointer;
                flex: 1 1 50%;

                &.up {
                    border-radius: 0 0.5rem 0 0;
                }

                &.down {
                    border-radius: 0 0 0.5rem 0;
                }
            }
        }
    }
}

.edit-prizes {
    display: flex;
    background: white;
    border-radius: 1rem;
    padding: 1.5rem;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }

    &__card-search {
        display: flex;
        background: white;
        flex-direction: column;
        flex: 1 1 33%;
        gap: 0.5rem;
        position: sticky;
        align-self: flex-start;
        top: 0.5rem;
        margin: 0 1rem;
        z-index: 1;

        @media only screen and (max-width: 500px) {
            top: 0;
            margin: 0;
            width: 100%;
            padding: 1rem 0.5rem;
        }

        &--results {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0;
                border-radius: 0.5rem;
                padding: 0.5rem;
                cursor: pointer;
                min-height: 3rem;
                width: 100%;
            }
        }
    }

    &__card-view {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        button {
            margin: 0 1rem;
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-items: center;
        }
    }

    &__print-selector {
        width: 100%;
        display: flex;
        flex: 1 1 auto;
        gap: 5%;

        &.animate {
            transition: margin-left 0.3s;
        }

        &--print-option {
            width: 30%;
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            filter: grayscale(100%);
            position: relative;

            &.animate {
                transition: width 0.3s, filter 0.3s;
            }

            &.active {
                width: 75%;
                filter: none;
                max-width: 75%;
            }
        }
    }
}
