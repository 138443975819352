.trade-message {
    &--prompt {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;

        h1 {
            font-size: .8rem;
            font-weight: 700;
            line-height: 1;
            margin: 0;       
        }
    }
}