:root {
    --spacer-xs: 0.125rem;
    --spacer-sm: 0.25rem;
    --spacer-md: 0.5rem;
    --spacer-lg: 0.75rem;
    --spacer-xl: 1rem;
    @media only screen and (min-width: 850px) {
        --spacer-xs: 0.25rem;
        --spacer-sm: 0.5rem;
        --spacer-md: 1rem;
        --spacer-lg: 1.25rem;
        --spacer-xl: 1.5rem;
    }
}
