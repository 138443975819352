.objectives-manager {
    &__create-objective {
        display: flex;
        align-items: baseline;

        &--description-input {
            margin-left: 1rem !important;
        }
    }

    &__int-cell {
        flex: 1 1 10%;

        & input[type="number"] {
            font-size: 1rem;
        }
    }

    &__description-cell {
        flex: 1 1 75%;
        text-align: left;

        & input[type="text"] {
            font-size: 1rem;
            text-align: left;
            margin: 0;
        }
    }

    &__controls-cell {
        display: flex;
        flex: 1 1 15%;
        justify-content: space-evenly;
        gap: 0.5rem;

        &--edit,
        &--destroy,
        &--submit {
            margin: 0;
            flex: 1 1 50%;
            min-width: 42px;
            font-size: 1.5rem;
        }
    }

    &__reroll-updater {
        display: flex;
        align-items: top;
        gap: 1rem;
        margin-bottom: 1rem;

        input.updater {
            margin-bottom: 0;
            text-align: center;
            font-size: 1.5rem;
        }

        &--title {
            margin-bottom: 0;
        }
    }
}
