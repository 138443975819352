.filter {
    &__option-group-toggle {
        background-color: var(--color-fill-neutral);
        color: var(--color-text-neutral);
        font-weight: 700;
        padding: 0.75rem;
        width: 100%;
        border-bottom: 2px solid var(--color-fill-theme-highlight);

        &--actives {
            font-size: 0.75rem;
            font-weight: 400;
            color: var(--color-text-neutral);
        }

        i {
            margin-left: 0.75rem;
        }
        border-radius: 0;
    }

    &__option-group {
        padding: 1rem;
    }

    &__option {
        text-align: left;

        label {
            font-size: 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin: 0.25rem 0;
            
            .ms {
                display: flex;
                -webkit-border-radius: 500px;
                border-radius: 500px;
                -webkit-box-shadow: -1px 1px 0 rgba(0,0,0,0.85);
                box-shadow: -1px 1px 0 rgba(0,0,0,0.85);
                overflow: hidden;
                width: 20px;
                height: 20px;
                justify-content: center;
                align-items: center;

                &.ms-w {
                  background-color: var(--color-white-mana);
                }

                &.ms-u {
                  background-color: var(--color-blue-mana);
                }

                &.ms-b {
                  background-color: var(--color-black-mana);
                }

                &.ms-r {
                  background-color: var(--color-red-mana);
                }

                &.ms-g {
                  background-color: var(--color-green-mana);
                }

                &.ms-c {
                  background-color: var(--color-colorless-mana);
                }
            }
        }
    }

    &__apply-button,
    &__clear-all-button {
        display: inline-block;
        width: 50%;
        margin: 1rem 0;
        align-self: center;
    }
    &__clear-all-button {
        margin: 0;
    }
}
