//Animations
:root {
    --Caribbean-Green: #00d285;
    --Crimson: #dc143c;
    --Eerie-Black: #270c1d;
    --Fountain-Blue: #55bcc9;
    --Mellow-Apricot: #efbd76;
}
//Background saturation animation
@keyframes fadeInBGFilter {
    0% {
        -webkit-filter: saturate(0.25);
        filter: saturate(0.25);
    }
}

//Rankings name entry animation
@keyframes bigEntrance {
    0% {
        transform: translateY(10px);
        opacity: 0.2;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes bigEntrance {
    0% {
        transform: translateY(10px);
        opacity: 0.2;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

//Logo Color Cylcle on hover
@keyframes mtgColorCycle {
    16% {
        fill: var(--color-white-mana);
    }

    32% {
        fill: var(--color-blue-mana);
    }

    64% {
        fill: var(--color-green-mana);
    }

    82% {
        fill: var(--color-red-mana);
    }

    98% {
        fill: var(--color-black-mana);
    }
}

@-webkit-keyframes mtgColorCycle {
    16% {
        fill: var(--color-white-mana);
    }

    32% {
        fill: var(--color-blue-mana);
    }

    64% {
        fill: var(--color-green-mana);
    }

    82% {
        fill: var(--color-red-mana);
    }

    98% {
        fill: var(--color-black-mana);
    }
}

@keyframes mtgBackgroundColorCycle {
    16% {
        background-color: var(--color-white-mana);
    }

    32% {
        background-color: var(--color-blue-mana);
    }

    64% {
        background-color: var(--color-green-mana);
    }

    82% {
        background-color: var(--color-red-mana);
    }

    98% {
        background-color: var(--color-black-mana);
    }
}

@-webkit-keyframes mtgColorCycle {
    16% {
        background-color: var(--color-white-mana);
    }

    32% {
        background-color: var(--color-blue-mana);
    }

    64% {
        background-color: var(--color-green-mana);
    }

    82% {
        background-color: var(--color-red-mana);
    }

    98% {
        background-color: var(--color-black-mana);
    }
}

@keyframes growVert {
    0% {
        transform: scaleY(0);
    }
}

@-webkit-keyframes growVert {
    0% {
        transform: scaleY(0);
    }
}

@keyframes growHorz {
    0% {
        transform: scaleX(0);
    }
}

@-webkit-keyframes growHorz {
    0% {
        transform: scaleX(0);
    }
}
