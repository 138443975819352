form.edit_user {
    width: 100%;
}

.dashboard-edit-user-details {
    &__title {
        color: var(--color-text-inverse);
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        .form--basic {
            .switch.optional {
                margin: var(--spacer-xs) 0;
                color: var(--color-text-inverse);
            }
        }
    }

    &__gravatar {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            max-width: 250px;
            border-radius: 100%;
            border: 0.5rem solid var(--color-fill-inverse);
        }

        a {
            margin-left: 8rem;
            margin-top: -3rem;
            border-radius: 3rem;
            padding: 1rem;
            font-size: 2rem;
            display: flex;
        }
    }

    &__discord {
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding: 1rem;
        margin: 1rem auto 1rem auto;
        align-items: center;
        font-size: 1.25rem;

        i {
            font-size: 2rem;
        }

        &.blank {
            border-radius: 3rem;
            color: #7289da;
            background: none;
            border: 3px solid #7289da;
        }

        &.linked {
            border-radius: 3rem;
            color: white;
            background: #7289da;
        }
    }
}
